

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductGradeModal from "@/components/modals/forms/AddProductGradeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";
import AddAttributeRangeModal from "@/components/modals/forms/AddAttributeRangeModal.vue";


export default defineComponent({
 props: 
  {
      id: { required: true }
  },
  name: "price-listing",
  components: {
   AddAttributeRangeModal,
  },
  
  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);
    const searchTearm = ref('');
    var ids = ref(props.id);
    interface ViewPrice {
      attribute_name,
      min_value,
      max_value    
      uom_name,
    };

    var paginationData = ref({})
    var resultsView1 = ref<Array<ViewPrice>>([]);
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const attr_range_modal = (category_id) => {
      const modal = new Modal(document.getElementById("kt_modal_range_attribute"));
      modal.show();
      (document.querySelector('#cat_id') as HTMLInputElement).value = category_id;
    }
    
    var tableData = ref<Array<ViewPrice>>([]);
  
     const getProductPriceDetails = async () => {
     try {

      var values = { "category_id"  :  props.id}

      await store.dispatch(Actions.CUST_ATTRIBUTE_RANGE_LIST, values).then(({ data }) => {

      tableData.value = ([]);

          for (var j = 0; j < data.length; j++){
              resultsView1.value = Array({
                  uom_name: data[j].uom_name,
                  attribute_name:data[j].attribute_name,
                  min_value: data[j].min_value,
                  max_value: data[j].max_value,
                  
              })
              tableData.value.splice(j, resultsView1.value.length, ...resultsView1.value); 
          }
           loadingData.value = false;

        }).catch(({ response }) => {
          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };
    
      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });
                    
      onMounted( async () => {
         await getProductPriceDetails();
      });  

      
      
    return {
      tableData,      
      checkedCompany,
      resultsView1,
      getProductPriceDetails,
      attr_range_modal,
      ids,
      count,      
      paginationData,
      loadingData
    };  

  }  

});
