
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditProductCategoriesModal from "@/components/modals/forms/EditProductCategoriesModal.vue";
import CategoryAttributeList from "@/views/apps/product/CategoryAttributeList.vue";
import CategoryAttributeRangeList from "@/views/apps/product/CategoryAttributeRangeList.vue";
import CategoryGradeList from "@/views/apps/product/CategoryGradeList.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "categories-details",
  components: {
    CategoryGradeList,
    EditProductCategoriesModal,
    CategoryAttributeList,
    CategoryAttributeRangeList
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();
    var tag_list: any[] = []
    var industry_type_list : any[] = []
    var attribute : any[] = []

   interface ViewCategories {
      category_id,
      category_name,
      category_form_name,
      category_description,
      parent_category_name,
      shape_name,
      category_stage_name,
      category_length_type_name,
      category_industry_types,
      tag_ids,
      active : {
        label :string;
        color :string;
      }
    };   

    interface ViewAttributes {
      category_id : number ,       
      attribute_name : string ,
      attribute_data : string,
    };   
    
    var resultsView = ref<ViewCategories>();
    var resultsView1 = ref<Array<ViewAttributes>>([])
    const loadingData = ref<boolean>(true);
    const count = ref(0);

    resultsView.value = {
      category_id: "",
      category_name: "",
      category_form_name: "",
      category_description:"",
      shape_name: "",
      parent_category_name: "",
      category_stage_name: "",
      category_length_type_name: "",
      category_industry_types: "",
      tag_ids: "",
      active : {
        label :"",
        color :"",
      }
    }

    const attr_modal = (category_id) => {
      const modal = new Modal(document.getElementById("kt_modal_attribute"));
      modal.show();
      (document.querySelector('#cat_id') as HTMLInputElement).value = category_id;
    }

    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

     const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getAttributeList()
      
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getAttributeList()
     
    }
    

    const getCategoryDetails = async () => {
     
      try {

      var values = { "category_id"  :  props.id,"search_term" : "", "page"  : 1, "records_per_page" : 1}

      await store.dispatch(Actions.CUST_GET_CATEGORY_LIST, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }

        for (var i = 0; i < data.tags.length; i++){
              var obj = data.tags[i];
              tag_list.push(obj.tag_name)
          }

          for (var j = 0; j < data.industry_types.length; j++){
              var industry_obj = data.industry_types[j];
              industry_type_list.push(industry_obj.company_industry_type_name)
          }
     
        resultsView.value = {
          category_id :  data.category_id,
          category_name : data.category_name,
          category_form_name: data.category_form,
          category_description : data.category_description,
          shape_name : data.shape,
          category_stage_name : data.category_stage,
          category_length_type_name : data.category_length_type,
          category_industry_types : industry_type_list,          
          parent_category_name: data.parent_category_name,
          tag_ids : tag_list,
          active: {
            label: active_label,
            color: active_color
          },
        }

        
      console.log("categories DetailsVue")
      console.log(resultsView)
              

      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    var tableData = ref<Array<ViewAttributes>>([]);
  
    const getAttributeList = async () => {
     
      try {

      var values = { "category_id"  :  props.id, "page"  : parseInt(paginationData.value['activePage']), "records_per_page" : parseInt(paginationData.value['perPage'])}

      await store.dispatch(Actions.CUST_GET_ATTRIBUTE_LIST, values).then(({ data }) => {
          tableData.value = ([]);

          for (var j = 0; j < data.attribute_list.length; j++){
              resultsView1.value = Array({
                  category_id :  data.attribute_list[j]['category_attribute_id'],
                  attribute_name : data.attribute_list[j]['attribute_name'],
                  attribute_data:  data.attribute_list[j]['attribute_data'],
              })
              tableData.value.splice(j, resultsView1.value.length, ...resultsView1.value); 
          }  
               
           loadingData.value = false;

        }).catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };
    

    onMounted( async () => {
      await getCategoryDetails();
      await getAttributeList();
      setCurrentPageBreadcrumbs("Categories Details", ["Category"]);
    });     


    return {
      changePageChange,
      refreshData,
      resultsView,
      resultsView1,
      getAttributeList,
      attr_modal,
      loadingData,
      ids,
      tableData,
    };

  }
});
