

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddProductGradeModal from "@/components/modals/forms/AddProductGradeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import AddCategoryGradeModal from "@/components/modals/forms/AddCategoryGradeModal.vue";
import { Modal } from "bootstrap";
import { Actions } from "@/store/enums/StoreEnums";


export default defineComponent({
 props: 
  {
      id: { required: true }
  },
  name: "price-listing",
  components: {
   AddCategoryGradeModal,
  },
  
  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);
    const searchTearm = ref('');
    
    var ids = ref(props.id);
    interface ViewPrice {
      category_name,  
      standard_no,
      grade_name,
    };

    var paginationData = ref({})
    var resultsView1 = ref<Array<ViewPrice>>([]);
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getCatGradeDetails()
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getCatGradeDetails()
    }

    const attr_modal = (category_id) => {
      const modal = new Modal(document.getElementById("kt_modal_add_catgrade"));
      modal.show();
      (document.querySelector('#cat_id') as HTMLInputElement).value = category_id;
    }
    
    var tableData = ref<Array<ViewPrice>>([]);
  
     const getCatGradeDetails = async () => {
     try {

      var values = { 
        "category_id"  :  props.id,
        "page"  : parseInt(paginationData.value['activePage']), 
        "records_per_page" : parseInt(paginationData.value['perPage']),}

      await store.dispatch(Actions.CUST_CATEGORY_GRADE_MAPPING_LIST, values).then(({ data }) => {

      tableData.value = ([]);

          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          console.log("paginationData.value")
          console.log(paginationData.value)
          var resultsM = ref<Array<ViewPrice>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""     
        for (let j = 0; j < data.result_list.length; j++) {

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
          resultsView1.value = Array({
            category_name: data.result_list[j].category_name,
            standard_no: data.result_list[j].standard_no,
            grade_name: data.result_list[j].grade_name,
          })
          tableData.value.splice(j, resultsView1.value.length, ...resultsView1.value); 
        }
          loadingData.value = false;

        }).catch(({ response }) => {
          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };
    
      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });
                    
      onMounted( async () => {
         await getCatGradeDetails();
      });  

      
      
    return {
      tableData,      
      checkedCompany,
      resultsView1,
      getCatGradeDetails,
      changePageChange,
      refreshData,
      attr_modal,
      ids,
      count,      
      paginationData,
      loadingData
    };  

  }  

});
